<template>
  <div class="card" :style="styleBack ? 'backgroundColor: transparent' : 'backgroundColor: #F6F6F6' ">
    <div class="card-img">
      <router-link
        class="cursor-pointer"
        tag="div"
        :to="{ name: to, params: { slug: id } }"
        :style="
        ` overflow: hidden;
          height: 235px;
          background: url('${ image && image.preview ? image.preview : ''}') center center;
          background-size: cover;
          border-top-right-radius: 8px;
          border-top-left-radius: 8px;
        `"
      >
        <!--        <img :src="image" alt="">-->
      </router-link>
    </div>
    <promo-timer
      class="positionTimer"
      v-if="propsTimer"
      width="272px"
    />
    <div :class="[bodyPadding ? 'card-body' : 'card-body without-padding']">
      <div class="d-flex justify-content-between">
        <h3 class="" v-html="title.substr(0, 50)">...</h3>
        <div class="day" style="margin-top: 6px;">{{date}}</div>
      </div>


      <div class="withoutMargin" v-html="heading"></div>

      <router-link
        tag="button"
        :to="{ name: to, params: { slug: id } }"
        class="card_link scrollLink"
      >
        {{ localize('button')['more'] }}
      </router-link>
    </div>

  </div>
</template>

<script>
import VueTypes from 'vue-types';
import localize from '../filters/localize.filter';
import PromoTimer from './timer/PromoTimer';


export default {
  props: {
    id: VueTypes.any.isRequired,
    to: VueTypes.string.def('news-detail'),
    slug: VueTypes.string.isRequired,
    image: VueTypes.object,
    heading: VueTypes.string.isRequired,
    title: VueTypes.string.isRequired,
    date: VueTypes.string.isRequired,
    propsTimer: VueTypes.bool.def(false),
    styleBack: VueTypes.bool.def(false),
    bodyPadding: VueTypes.bool.def(true),
  },
  components: {
    PromoTimer,
  },
  data() {
    return {};
  },
  methods: {
    localize,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/mixins/font.scss";
  .card {
    border-radius: 8px;
    height: 520px;
    position: relative;

    @media (max-width: $mobile) {
      margin-bottom: 20px;
    }

    .card-img {
      /*position: relative;*/

      img {
        width: 100%;
      }
    }

    .secondCard {
      background: transparent !important;
    }

    .card-body {
      padding: 16px 24px 36px;
      /*position: relative !important;*/

      h3 {
        @include font(22px, 600, 28px, $black, false);
        overflow: hidden;

        @media (max-width: $mobile) {
          font-size: 16px;
          height: 36px;
        }
      }

      p {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.01em;
        color: $black;
        height: 80px;
        overflow: hidden;

        @media (max-width: $mobile) {
          font-size: 14px;
        }
      }

      .card_link {
        /*position: fixed;*/
        /*bottom: 25px;*/
        position: absolute;
        bottom: 20px;
        display: flex;
        align-items: center;

        font-size: 16px;
        font-weight: bold;
        line-height: 24px;
        padding: 16px 24px;
        border: none;
        outline: none;
        border-radius: $border-radius;

        color: $main;
        background-color: $peach;


        &:hover {
          cursor: pointer;
          background-color: lighten($peach, 10%);
          color: lighten($main, 10%);
        }

        &:first-child {
          margin-top: 10px;
        }

        svg {
          margin-left: 14px;
        }
      }
    }

    .without-padding {
      padding: 24px 0 0 0;
    }
  }

  .cardHeight {
    height: 530px !important;
  }

  @media (max-width: 575px) {
    .card {

      .card-body {
        padding: 16px 16px 0 16px;
      }

      .date {
        font-size: 9px;
        line-height: 11px;
        opacity: 0.3;
        margin-bottom: 12px;
      }

      h3 {
        font-size: 16px;
        line-height: 21px;
        margin-bottom: 4px;
      }

      p {
        font-size: 13px;
        line-height: 18px;
        letter-spacing: 0.01em;
        color: $black;
        margin-bottom: 16px;
      }
    }
    .card_link {
      font-size: 13px;
      line-height: 18px;
      display: flex;
      align-items: center;
      letter-spacing: 0.01em;
      color: $black;
    }
  }

  .positionTimer {
    position: absolute;
    right: 15%;
    top: 35%;
    background-color: $grey;
    @media (max-width: $mobile) {
      width: 84% !important;
      right: 8%;
      top: 35%;
    }
    @media (max-width: $tablet) {
      width: 84% !important;
      right: 8%;
      top: 35%;
    }
    @media (max-width: $tablet-xl) {
      right: 2%;
      top: 35%;
      width: 80% !important;
    }
    @media (max-width: $notebook) {
      right: 9%;
      top: 35%;
      width: 80% !important;
    }
  }


</style>


<style scoped>
  .withoutMargin {
    margin-top: 15px;
    display: -webkit-box;
    max-width: 300px;
    max-height: 80px;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
</style>
