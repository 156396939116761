export default [
  {
    id: 0,
    heading: "Nunc neque vulputate ut.",
    title:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fringilla gravida porta aenean quam aenean quam enim sit a. Nunc nunc vulputate donec nulla eu lacus.",
    href: "/",
    text: '',
    dateTime: "СЕГОДНЯ",
    dateDay: "13:23",
  },
  {
    id: 1,
    heading: "Nunc neque vulputate ut.",
    title:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fringilla gravida porta aenean quam aenean quam enim sit a. Nunc nunc vulputate donec nulla eu lacus.",
    href: "/",
    text: '',
    dateTime: "СЕГОДНЯ",
    dateDay: "13:23",
  },
  {
    id: 2,
    heading: "Nunc neque vulputate ut.",
    title:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fringilla gravida porta aenean quam aenean quam enim sit a. Nunc nunc vulputate donec nulla eu lacus.",
    href: "/",
    text: '',
    dateTime: "СЕГОДНЯ",
    dateDay: "13:23",
  },
  {
    id: 3,
    heading: "Nunc neque vulputate ut.",
    title:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fringilla gravida porta aenean quam aenean quam enim sit a. Nunc nunc vulputate donec nulla eu lacus.",
    href: "/",
    text: '',
    dateTime: "СЕГОДНЯ",
    dateDay: "13:23",
  },
  {
    id: 4,
    heading: "Nunc neque vulputate ut.",
    title:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fringilla gravida porta aenean quam aenean quam enim sit a. Nunc nunc vulputate donec nulla eu lacus.",
    href: "/",
    text: '',
    dateTime: "СЕГОДНЯ",
    dateDay: "13:23",
  },
];
