var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card",style:(_vm.styleBack ? 'backgroundColor: transparent' : 'backgroundColor: #F6F6F6')},[_c('div',{staticClass:"card-img"},[_c('router-link',{staticClass:"cursor-pointer",style:(` overflow: hidden;
        height: 235px;
        background: url('${ _vm.image && _vm.image.preview ? _vm.image.preview : ''}') center center;
        background-size: cover;
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
      `),attrs:{"tag":"div","to":{ name: _vm.to, params: { slug: _vm.id } }}})],1),(_vm.propsTimer)?_c('promo-timer',{staticClass:"positionTimer",attrs:{"width":"272px"}}):_vm._e(),_c('div',{class:[_vm.bodyPadding ? 'card-body' : 'card-body without-padding']},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('h3',{domProps:{"innerHTML":_vm._s(_vm.title.substr(0, 50))}},[_vm._v("...")]),_c('div',{staticClass:"day",staticStyle:{"margin-top":"6px"}},[_vm._v(_vm._s(_vm.date))])]),_c('div',{staticClass:"withoutMargin",domProps:{"innerHTML":_vm._s(_vm.heading)}}),_c('router-link',{staticClass:"card_link scrollLink",attrs:{"tag":"button","to":{ name: _vm.to, params: { slug: _vm.id } }}},[_vm._v(" "+_vm._s(_vm.localize('button')['more'])+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }