<template>
  <div class="timer" :style="{ width: width }">
    <div class="time days">
      <p>{{ days }}</p>
      <span>{{ localize('akciya')['days'] }}</span>
    </div>
    <div class="time hours">
      <p>{{ hours }}</p>
      <span>{{ localize('akciya')['hours'] }}</span>
    </div>
    <div class="time minutes">
      <p>{{ minutes }}</p>
      <span>{{ localize('akciya')['minutes'] }}</span>
    </div>
    <div class="time day">
      <p>{{ seconds }}</p>
      <span>{{ localize('akciya')['seconds'] }}</span>
    </div>
  </div>
</template>

<script>
import VueTypes from 'vue-types';
import localize from '../../filters/localize.filter';

export default {
  name: 'PromoTimer',
  props: {
    time: VueTypes.string.def('Mar 26, 2022 16:00:00'),
    width: VueTypes.string.def('400px'),
  },
  data: () => ({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
    remainingDate: 0,
  }),
  methods: {
    localize,
    countdown() {
      setInterval(() => {
        const promeDate = new Date(this.time);
        const currentDate = new Date();
        this.remainingDate = promeDate - currentDate;

        const hours24 = 1000 * 60 * 60 * 24; // 1 day
        const minutes60 = 1000 * 60 * 60; // 1 hour
        const seconds60 = 1000 * 60; // 1 minute
        const seconds = 1000; // 1 second

        if (this.remainingDate < 0) {
          this.days = 0;
          this.hours = 0;
          this.minutes = 0;
          this.seconds = 0;
        } else {
          this.days = Math.floor(this.remainingDate / hours24);
          this.hours = Math.floor((this.remainingDate % hours24) / minutes60);
          this.minutes = Math.floor((this.remainingDate % minutes60) / seconds60);
          this.seconds = Math.floor((this.remainingDate % seconds60) / seconds);
        }

      }, 1000);
    },
  },
  created() {
    this.countdown();
  },
};
</script>

<style lang="scss" scoped>
  .timer {
    display: flex;
    text-align: center;
    padding: 10px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.08);
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.12);

    @media (max-width: $mobile) {
      width: 100% !important;
    }

    .time {
      width: 25%;
      border-right: 1px solid rgba(0, 0, 0, 0.08);

      &:last-child {
        border-right: unset;
      }

      p {
        font-weight: 700;
        font-size: 32px;
        line-height: 33px;
      }

      span {
        margin-top: 0;
        font-size: 12px;
      }
    }
  }
</style>